import { call } from '../../helpers';
import { ApiResponse } from '../../types';
import { InviteForm } from './inviteForms.types';

export async function getInviteForm(
    uuid: string,
    requestInit?: RequestInit
): Promise<ApiResponse<InviteForm, 200> | ApiResponse<{ detail: string }, 404>> {
    const response = await call(`/api/microwave/invite-forms/${uuid}/`, requestInit);

    if (response.status === 404) {
        return {
            status: response.status,
            data: await response.json(),
        };
    }

    if (!response.ok) {
        throw new Error('Failed to fetch Invite Form');
    }

    return {
        status: 200,
        data: await response.json(),
    };
}
